import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { iconInfoCircle } from "@mittwald/flow-icons/dist/infoCircle";
import { MittwaldApi } from "../../../api/Mittwald";

export type ModelRelationReferenceApiSchema = Omit<
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_AggregateReference,
  "id"
>;

export class ModelRelationType {
  public readonly domain: string;
  public readonly namespacedDomain: string;
  public readonly aggregate: string;
  public readonly icon: IconLookup;

  public static readonly unknown = new ModelRelationType(
    "unknown",
    "unknown",
    iconInfoCircle,
  );

  public constructor(domain: string, aggregate: string, icon: IconDefinition) {
    this.domain = domain;
    this.namespacedDomain = `mittwald.${domain}.v1`;
    this.aggregate = aggregate;
    this.icon = icon;
  }

  public static fromApiData(
    data: ModelRelationReferenceApiSchema,
  ): ModelRelationType {
    return new ModelRelationType(data.domain, data.aggregate, iconInfoCircle);
  }

  public matches(data: ModelRelationReferenceApiSchema): boolean {
    return (
      (this.domain === data.domain || this.namespacedDomain === data.domain) &&
      this.aggregate === data.aggregate
    );
  }
}

export default ModelRelationType;
