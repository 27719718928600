import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import { TextField } from "@mittwald/flow-components/dist/components/TextField/TextField";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import AddressPrefixInput from "../../../components/AddressPrefixInput";
import CityInput from "../../../components/CityInput";
import CompanyInput from "../../../components/CompanyInput";
import CountrySelect from "../../../components/CountrySelect";
import { EmailAddressInput } from "../../../components/EmailAddressInput";
import FirstNameInput from "../../../components/FirstNameInput";
import HouseNumberInput from "../../../components/HouseNumberInput";
import LastNameInput from "../../../components/LastNameInput";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import PostalCodeInput from "../../../components/PostalCodeInput";
import { SalutationSelect } from "../../../components/SalutationSelect";
import StreetInput from "../../../components/StreetInput";

interface Props {
  type: "owner" | "recipient";
  isDisabled?: boolean;
}

export const ContactFormInputs: FC<Props> = (props) => {
  const formContext = useFormContext();
  const { type, isDisabled } = props;

  const companyWatch = formContext.watch(`${type}.company`);
  const firstNameWatch = formContext.watch(`${type}.firstName`);
  const lastNameWatch = formContext.watch(`${type}.lastName`);
  const countryCodeWatch = formContext.watch(`${type}.address.countryCode`);
  const isPersonalNameRequired =
    !companyWatch || (companyWatch && (firstNameWatch || lastNameWatch));

  return (
    <TranslationProvider name="contactFormInputs" type="section">
      <ColumnLayout medium={[1, 1]}>
        <CompanyInput
          disabled={isDisabled}
          name={`${type}.company`}
          required={!isPersonalNameRequired}
        />
        {type === "owner" && (
          <TextField disabled={isDisabled} label="vatId" name="vatId" />
        )}
      </ColumnLayout>
      <ColumnLayout medium={[1, 1]}>
        <AddressPrefixInput
          addressPrefix={`${type}.address.addressPrefix`}
          disabled={isDisabled}
        />
      </ColumnLayout>
      <ColumnLayout medium={[1, 1]}>
        <SalutationSelect disabled={isDisabled} name={`${type}.salutation`} />
      </ColumnLayout>
      <ColumnLayout medium={[1, 1]}>
        <FirstNameInput
          disabled={isDisabled}
          name={`${type}.firstName`}
          required={isPersonalNameRequired}
        />
        <LastNameInput
          disabled={isDisabled}
          name={`${type}.lastName`}
          required={isPersonalNameRequired}
        />
        <ColumnLayout small={[2, 1]}>
          <StreetInput disabled={isDisabled} name={`${type}.address.street`} />
          <HouseNumberInput
            disabled={isDisabled}
            name={`${type}.address.houseNumber`}
          />
        </ColumnLayout>
        <ColumnLayout small={[1, 2]}>
          <PostalCodeInput
            countryCode={countryCodeWatch}
            disabled={isDisabled}
            name={`${type}.address.zip`}
          />
          <CityInput disabled={isDisabled} name={`${type}.address.city`} />
        </ColumnLayout>
        <CountrySelect
          disabled={isDisabled}
          name={`${type}.address.countryCode`}
        />
      </ColumnLayout>
      <ColumnLayout medium={[1, 1]}>
        <EmailAddressInput
          disabled={isDisabled}
          name={`${type}.emailAddress`}
          rules={{ required: true }}
        />
        <PhoneNumberInput disabled={isDisabled} name={`${type}.phoneNumber`} />
      </ColumnLayout>
    </TranslationProvider>
  );
};
export default ContactFormInputs;
