import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";

interface Props {
  name?: string;
  disabled?: boolean;
  countryCode?: string;
}

export const PostalCodeInput: FC<Props> = (props) => {
  const validationRules = {
    required: true,
    validate: (value: string) => {
      if (props.countryCode === "DE") {
        return /^\d{5}$/.test(value) || "form.validation.germanPostalCode";
      }

      return true;
    },
  };

  return (
    <TextField
      autoComplete="postal-code"
      disabled={props.disabled}
      key={`postal-${props.countryCode}`}
      label="zip"
      name={props.name ?? "zip"}
      rules={validationRules}
    />
  );
};

export default PostalCodeInput;
