import startRouteInvalidation from "@mittwald/flow-app-utils/dist/api/startRouteInvalidation";
import { apiOperationResourceStore } from "@mittwald/flow-lib/dist/resources/ApiOperationResource";
import { ingressListIngressesCompatibleWithCertificate } from "../api/Mittwald";
import websocketGateway from "../api/websocketGateway";
import autorunWithCleanup, { CleanupFn } from "../lib/autorunWithCleanup";
import { TokenRefresh } from "../model/signup/TokenRefresh";
import User from "../model/user/User";
import sessionStore from "../store/session";

const resetApiResourceStore = (): void => {
  apiOperationResourceStore
    .findByTag("**")
    .forEach((r) => r.expire({ autoReload: false }));
  apiOperationResourceStore.clear();
};

export const autorunAppInitialization = (): void => {
  autorunWithCleanup(() => {
    void TokenRefresh.startAutoRefresh();
  });

  autorunWithCleanup(() => {
    const accessToken = sessionStore.accessToken;

    const cleanupFunctions: CleanupFn[] = [];

    if (accessToken) {
      resetApiResourceStore();

      void websocketGateway.connect({
        uri: import.meta.env.VITE_APP_CENTRIFUGE_URI,
        accessToken,
      });

      cleanupFunctions.push(() => websocketGateway.disconnect());

      void User.me().then((user) => {
        cleanupFunctions.push(
          startRouteInvalidation(websocketGateway, user.id, (res) => {
            return (
              res.tags.matches("GET") ||
              res.tags.matches(
                ingressListIngressesCompatibleWithCertificate.operationId,
              )
            );
          }),
        );
      });
    }

    return cleanupFunctions;
  });
};
