import { useMemo } from "react";
import { mittwaldApi } from "../../../api/Mittwald";
import { mittwaldApiV1 } from "../../../api/MittwaldV1";
import ListModel from "../../misc/ListModel";
import DomainPrice from "./DomainPrice";

const sortedPrioTlds = [
  "de",
  "com",
  "at",
  "eu",
  "net",
  "info",
  "org",
  "ch",
  "biz",
  "shop",
];

export class DomainPriceList extends ListModel<DomainPrice> {
  public constructor(
    mStudioItems: DomainPrice[],
    controllCenterItems: DomainPrice[],
  ) {
    const mergedItems = [...mStudioItems];
    controllCenterItems.forEach((controlItem) => {
      const existing = mStudioItems.find(
        (mStudioItem) => mStudioItem.tld === controlItem.tld,
      );
      if (!existing) {
        mergedItems.push(controlItem);
      } else {
        existing.controlCenter = true;
        existing.startPrice =
          existing.startPrice > controlItem.startPrice
            ? controlItem.startPrice
            : existing.startPrice;
      }
    });

    super(mergedItems);
  }

  public static useLoadAll(): DomainPriceList {
    const mStudioArticles = mittwaldApi.articleListArticles
      .getResource({
        query: {
          orderable: ["full"],
          templateNames: ["domain"],
          limit: 2000,
        },
      })
      .useWatchData();

    const controlCenterTlds = mittwaldApiV1.listPublicToplevelDomains
      .getResource({})
      .useWatchData();

    const mStudioTldsMemo = useMemo(
      () => mStudioArticles.map(DomainPrice.fromArticleApiData),
      [mStudioArticles],
    );

    const controlCenterTldsMemo = useMemo(
      () => controlCenterTlds.map(DomainPrice.fromTldApiData),
      [controlCenterTlds],
    );

    return new DomainPriceList(mStudioTldsMemo, controlCenterTldsMemo);
  }

  public static useLoadAllmStudio(): DomainPriceList {
    const mStudioArticles = mittwaldApi.articleListArticles
      .getResource({
        query: {
          orderable: ["full"],
          templateNames: ["domain"],
          limit: 2000,
        },
      })
      .useWatchData();

    const mStudioTldsMemo = useMemo(
      () => mStudioArticles.map(DomainPrice.fromArticleApiData),
      [mStudioArticles],
    );

    return new DomainPriceList(mStudioTldsMemo, []);
  }

  public useItemsSorted(): DomainPrice[] {
    const items = this.useItems();

    return useMemo(
      () =>
        this.items
          .slice()
          .sort((left, right) => {
            if (left.tld > right.tld) {
              return 1;
            }
            if (left.tld < right.tld) {
              return -1;
            }
            return 0;
          })
          .sort((left, right) => {
            if (sortedPrioTlds.includes(left.tld)) {
              return -1;
            }
            if (sortedPrioTlds.includes(right.tld)) {
              return 1;
            }

            return 0;
          })
          .sort((left, right) => {
            if (
              sortedPrioTlds.includes(left.tld) &&
              sortedPrioTlds.includes(right.tld)
            ) {
              const indexLeft = sortedPrioTlds.findIndex(
                (name) => left.tld === name,
              );
              const indexRight = sortedPrioTlds.findIndex(
                (name) => right.tld === name,
              );

              if (indexLeft > indexRight) {
                return 1;
              }
              if (indexLeft < indexRight) {
                return -1;
              }
            }
            return 0;
          }),
      [items],
    );
  }
}
