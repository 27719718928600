import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import useOptionalPathParams from "@mittwald/flow-lib/dist/hooks/useOptionalPathParams";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import Invoice from "../accounting/Invoice";
import { SpaceServerArticle, SpaceServerArticleOrderInput } from "../article";
import Contract from "../contract/Contract";
import ContractItem from "../contract/ContractItem";
import Order from "../order/Order";
import {
  CreateProjectInput,
  ProjectList,
  SpaceServerProject,
} from "../project";
import Server from "../server/Server";
import ServerList from "../server/ServerList";
import CustomerContact, { CustomerContactInputs } from "./CustomerContact";
import { CustomerInvite, CustomerInviteCreateInputs } from "./CustomerInvite";
import { CustomerList } from "./CustomerList";
import CustomerMembership from "./CustomerMembership";
import CustomerMembershipList from "./CustomerMembershipList";
import { CustomerRoleName } from "./CustomerRole";
import { InvoiceSettings } from "./InvoiceSettings";

export type CustomerApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Customer_Customer;

export type CustomerInvoiceSettingsApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Invoice_InvoiceSettings;

export interface CustomerUpdateInputs {
  owner?: CustomerContactInputs;
  vatId?: string;
  name?: string;
}

export type CustomerUseOrdersOptions =
  MittwaldApi.Paths.V2_Customers_CustomerId_Orders.Get.Parameters.Query;

export interface CustomerCreateInputs {
  name: string;
  owner?: CustomerContactInputs;
  vatId?: string;
}

export class Customer {
  public readonly id: string;
  public readonly customerNumber: string;
  public readonly name: string;
  public readonly data: CustomerApiData;
  public readonly contact?: CustomerContact;
  public readonly myRole?: CustomerRoleName;
  public readonly isAllowedToOrder: boolean = true;
  public readonly levelOfUndeliverableDunningNotice?: "first" | "second";

  protected constructor(data: CustomerApiData) {
    this.data = Object.freeze(data);
    this.id = data.customerId;
    this.customerNumber = data.customerNumber;
    this.name = data.name;
    this.contact = data.owner
      ? CustomerContact.fromApiData(data.owner)
      : undefined;
    this.myRole = data.executingUserRoles?.[0] as CustomerRoleName;
    this.isAllowedToOrder = !data.isInDefaultOfPayment && !data.isBanned;
    this.levelOfUndeliverableDunningNotice =
      data.levelOfUndeliverableDunningNotice;
  }

  public static fromApiData(data: CustomerApiData): Customer {
    return new Customer(data);
  }

  public useIsBankrupt(): boolean {
    const invoiceSettings = this.useInvoiceSettings();
    if (!invoiceSettings?.data.status) {
      return false;
    }

    return invoiceSettings.data.status.some(
      (status) => status.type === "bankrupt",
    );
  }

  public async loadInvoiceSettings(): Promise<
    CustomerInvoiceSettingsApiData | undefined
  > {
    const response =
      await mittwaldApi.invoiceGetDetailOfInvoiceSettings.request({
        path: {
          customerId: this.id,
        },
      });

    if (response.status === 404) {
      return;
    }

    assertStatus(response, 200);

    return response.content;
  }

  public async checkStatus(
    status: MittwaldApi.Components.Schemas.De_Mittwald_V1_Invoice_InvoiceSettingsStatus["type"],
  ): Promise<boolean> {
    const invoiceSettings = await this.loadInvoiceSettings();
    return (
      !invoiceSettings ||
      !!invoiceSettings.status?.some((s) => s.type === status)
    );
  }

  public static async createNew(
    inputs: CustomerCreateInputs,
  ): Promise<Customer> {
    const response = await mittwaldApi.customerCreateCustomer.request({
      requestBody: {
        name: inputs.name,
        owner: inputs.owner
          ? { ...inputs.owner, phoneNumbers: [inputs.owner.phoneNumber] }
          : undefined,
        vatId: inputs.vatId,
      },
    });

    assertStatus(response, 201);

    return Customer.loadById(response.content.customerId);
  }

  public static useLoadById(id: string): Customer {
    const data = mittwaldApi.customerGetCustomer
      .getResource({ path: { customerId: id } })
      .useWatchData();

    return new Customer(data);
  }

  public static useLoadByPathParam(): Customer {
    const { customerId } = usePathParams("customerId");
    return Customer.useLoadById(customerId);
  }

  public static async loadById(id: string): Promise<Customer> {
    const data = await mittwaldApi.customerGetCustomer.request({
      path: { customerId: id },
    });
    assertStatus(data, 200);
    return new Customer(data.content);
  }

  public static useLoadAll(): CustomerList {
    return CustomerList.useAll();
  }

  public useContracts(): Contract[] {
    const contracts = mittwaldApi.contractListContracts
      .getResource({
        path: { customerId: this.id },
        query: {},
      })
      .useWatchData();

    return contracts.map((c) => Contract.fromApiData(c));
  }

  public useContractItems(): ContractItem[] {
    return this.useContracts().flatMap((c) => c.allItems);
  }

  public useMembership(membershipId: string): CustomerMembership {
    return CustomerMembership.useLoad(membershipId, this.id);
  }

  public useMembershipByPathParam(): CustomerMembership {
    const { membershipId } = usePathParams("membershipId");
    return this.useMembership(membershipId);
  }

  public useMemberships(): CustomerMembershipList {
    return CustomerMembershipList.useLoadByCustomer(this);
  }

  public useOrders(opts: CustomerUseOrdersOptions = {}): Order[] {
    return mittwaldApi.orderListCustomerOrders
      .getResource({
        path: {
          customerId: this.id,
        },
        query: opts,
      })
      .useWatchData()
      .map((d) => Order.fromApiData(d));
  }

  public useInvoiceSettings(): InvoiceSettings | undefined {
    return InvoiceSettings.useTryLoadByCustomer(this);
  }

  public useInvoices(): Invoice[] {
    const data = mittwaldApi.invoiceListCustomerInvoices
      .getResource({
        path: {
          customerId: this.id,
        },
      })
      .useWatchData();

    return data.map((i) => Invoice.fromApiData(i));
  }

  public useInvoicesDry(): Invoice[] {
    mittwaldApi.invoiceListCustomerInvoices.getResource(null).useWatchData();

    return [];
  }

  public useProjects(): ProjectList {
    return ProjectList.useLoadByCustomerId(this.id);
  }

  public useServers(): ServerList {
    return new ServerList(Server.useLoadByCustomerId(this.id));
  }

  public async delete(): Promise<void> {
    const response = await mittwaldApi.customerDeleteCustomer.request({
      path: {
        customerId: this.id,
      },
    });

    assertStatus(response, 200);
  }

  public static useTryLoadById(id?: string): Customer | undefined {
    const data = mittwaldApi.customerGetCustomer
      .getResource(id ? { path: { customerId: id } } : null)
      .useWatchData({ optional: true });

    return data ? Customer.fromApiData(data) : undefined;
  }

  public static useTryLoadByPathParam(): Customer | undefined {
    const { customerId, serverId } = useOptionalPathParams();

    if (customerId) {
      return Customer.useLoadById(customerId);
    }
    if (serverId) {
      return Server.useLoadById(serverId).useCustomer();
    }
  }

  public async update(values: CustomerUpdateInputs): Promise<void> {
    const { vatId, owner, name } = values;

    const response = await mittwaldApi.customerUpdateCustomer.request({
      path: {
        customerId: this.id,
      },
      requestBody: {
        name: name ?? this.data.name,
        customerId: this.id,
        owner: owner
          ? { ...owner, phoneNumbers: [owner.phoneNumber] }
          : this.data.owner,
        vatId: vatId ?? this.data.vatId,
      },
    });

    assertStatus(response, 200);
  }

  public async createContractPartner(
    values: CustomerUpdateInputs,
  ): Promise<void> {
    await this.update(values);
  }

  public checkMyRoleIs(role: CustomerRoleName): boolean {
    return this.myRole === role;
  }

  public checkMyRoleIsIn(...roles: CustomerRoleName[]): boolean {
    const role = this.myRole;
    return !!role && roles.includes(role);
  }

  public useHasOpenInvoices = (): boolean => {
    const invoices = this.useInvoices();
    return invoices.some((invoice) => invoice.isOpen);
  };

  public async deleteAvatar(): Promise<void> {
    const response = await mittwaldApi.customerRemoveAvatar.request({
      path: { customerId: this.id },
    });

    assertStatus(response, 204);
  }

  public async requestAvatarUpload(): Promise<string> {
    const uploadTokenResponse =
      await mittwaldApi.customerRequestAvatarUpload.request({
        path: {
          customerId: this.id,
        },
      });

    assertStatus(uploadTokenResponse, 200);

    return uploadTokenResponse.content.refId;
  }

  public async createServer(
    input: SpaceServerArticleOrderInput,
  ): Promise<void> {
    await SpaceServerArticle.order({ ...input, customerId: this.id });
  }

  public async createProject(input: CreateProjectInput): Promise<void> {
    await SpaceServerProject.createNew(input);
  }

  public async inviteMember(values: CustomerInviteCreateInputs): Promise<void> {
    await CustomerInvite.createNew(values, this.id);
  }

  public hasContact(): boolean {
    return !!this.contact;
  }
}

export default Customer;
