import { useMemo } from "react";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { UserInputsRecordObject } from "../misc/userInput/UserInputRecordList";
import Domain, { DomainApiData, DomainOrder } from "./Domain";
import Ingress from "./Ingress";

export type DomainOrderOrIngress = Ingress | DomainOrder | Domain;

export interface DomainContactOption {
  domain: string;
  contact: UserInputsRecordObject;
}

export type DomainListQuery = MittwaldApi.Paths.V2_Domains.Get.Parameters.Query;

export class DomainList extends ListModel<Domain> {
  public static useTryLoadByQuery(
    query: DomainListQuery = {},
    filter?: (domain: DomainApiData) => boolean,
  ): DomainList {
    const domains = mittwaldApi.domainListDomains
      .getResource({ query })
      .useWatchData({ optional: true });

    const filteredDomains = filter ? (domains || []).filter(filter) : domains;
    const domainsMemo = useMemo(
      () => (filteredDomains || []).map((d) => Domain.fromApiData(d)),
      [domains],
    );

    return new DomainList(domainsMemo);
  }

  public static useLoadByQuery(
    query: DomainListQuery = {},
    filter?: (domain: DomainApiData) => boolean,
  ): DomainList {
    const domains = mittwaldApi.domainListDomains
      .getResource({ query })
      .useWatchData();

    const filteredDomains = filter ? domains.filter(filter) : domains;
    const domainsMemo = useMemo(
      () => filteredDomains.map((d) => Domain.fromApiData(d)),
      [domains],
    );

    return new DomainList(domainsMemo);
  }

  public static useLoadAllByProjectId(projectId?: string): DomainList {
    const query = projectId ? { projectId } : {};
    return DomainList.useLoadByQuery(query);
  }

  public static useTryLoadAllByProjectId(projectId?: string): DomainList {
    const query = projectId ? { projectId } : {};
    return DomainList.useTryLoadByQuery(query);
  }

  public static useLoadAllAccessible(): DomainList {
    return DomainList.useLoadByQuery();
  }

  public static useLoadAllByTld(tld: string): DomainList {
    const domainSearchName = `.${tld}`;
    return DomainList.useLoadByQuery({ domainSearchName }, (domain) =>
      domain.domain.endsWith(domainSearchName),
    );
  }

  public useContactRecordObjects(): DomainContactOption[] {
    const domains = this.useItems();

    const collectedContactHashes: string[] = [];
    const items: DomainContactOption[] = [];
    domains.forEach((d) => {
      const hashAdded =
        !!d.contactHash && collectedContactHashes.includes(d.contactHash);
      if (d.contacts.owner === undefined || hashAdded) {
        return;
      }
      if (d.contactHash) {
        collectedContactHashes.push(d.contactHash);
      }
      const contact = d.contacts.owner.toObject();
      items.push({ contact, domain: d.domain });
    });
    return items;
  }
}
