import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { FormController } from "@mittwald/flow-components/dist/components/Form";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import { InvoiceSettingsInputs } from "../../../../../../model/customer/InvoiceSettings";
import PaymentMethodSection from "../../../../customer/payment/components/PaymentMethodSection";
import { Section as NewSection } from "@mittwald/flow-react-components";

interface Props {
  form: FormController<
    InvoiceSettingsInputs & {
      otherRecipient: boolean;
    }
  >;
}

export const PaymentStep: FC<Props> = (props) => {
  const { form } = props;

  return (
    <TranslationProvider name="paymentStepContent" type="component">
      <WizardStep form={form} id="payment" indicatorText="paymentStep">
        <NewSection>
          <Section.Item headline="payment">
            <Text i18n="paymentDescription" />
          </Section.Item>
        </NewSection>
        <NewSection>
          <PaymentMethodSection isWizardStep />
        </NewSection>
        <NewSection>
          <Section.Item>
            <CheckBox name="otherRecipient" title="otherRecipient" />
          </Section.Item>
        </NewSection>
      </WizardStep>
    </TranslationProvider>
  );
};

export default PaymentStep;
