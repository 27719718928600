import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { arrayAccessor } from "@mittwald/awesome-node-utils/misc/ArrayAccessor";
import { ISODateWithRange } from "@mittwald/flow-components/dist/components/DatePicker";
import { SelectBoxOption } from "@mittwald/flow-components/dist/components/SelectBox";
import { StatusTypesProps } from "@mittwald/flow-components/dist/lib/statusType";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { CheckPageState } from "@mittwald/flow-lib/dist/pages/state";
import api from "../../api/clients/mittwald";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { IngressList } from "../domain/IngressList";
import { EmailAddressList } from "./EmailAddressList";

export type EmailAddressApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Mail_MailAddress;

export interface NewEmailAddressInputs {
  address: string;
  isCatchAll: boolean;
  mailbox: {
    enableSpamProtection: boolean;
    password: string;
    storage: number;
  };
}

export interface NewForwardAddressInputs {
  address: string;
  forwardAddresses: string[];
  isCatchAll: boolean;
}

export interface UpdateMailAddressInputs {
  address: string;
  isCatchAll: boolean;
}

export interface UpdateAutoresponderInputs {
  active: boolean;
  subject?: string;
  message?: string;
  hasPeriod?: boolean;
  period?: ISODateWithRange;
}

export interface UpdateForwardsInputs {
  forwardAddresses: string[];
}

export interface UpdatePasswordInputs {
  password: string;
}

export interface UpdateSpamProtectionInputs {
  active: boolean;
  autoDeleteSpam: boolean;
  folder: "inbox" | "spam";
  relocationMinSpamScore: SelectBoxOption["value"];
}

export interface UpdateQuotaInputs {
  storage: number;
}

export class EmailAddress {
  public readonly id: string;
  public readonly data: EmailAddressApiData;
  public readonly address: string;
  public readonly sendingDisabled: boolean;
  public readonly receivingDisabled: boolean;
  public readonly authenticationDisabled: boolean;
  public readonly storageAlmostExceeded: boolean;
  public readonly storageExceeded: boolean;
  public readonly isEmailAddress: boolean;
  public readonly isForwardAddress: boolean;
  public readonly isCatchAll: boolean;
  public static readonly maxMailboxSizeGB = 100;
  public static readonly minMailboxSizeGB = 0.2;
  public static readonly mailboxSizeStepGB = 0.1;

  public static readonly quotaUsageWarning = 0.75;
  public static readonly quotaUsageError = 0.9;

  public static readonly passwordAgeWarningInSeconds = 60 * 60 * 24 * 30 * 3; // three months / 90 days

  protected constructor(data: EmailAddressApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.address = data.address;
    this.sendingDisabled =
      !data.receivingDisabled && data.mailbox?.sendingEnabled === false;
    this.receivingDisabled =
      data.receivingDisabled && (!data.mailbox || data.mailbox.sendingEnabled);
    this.authenticationDisabled =
      data.receivingDisabled && data.mailbox?.sendingEnabled === false;
    this.storageAlmostExceeded =
      !!this.mailboxStorageStatus().warning &&
      !this.mailboxStorageStatus().error;
    this.storageExceeded = !!this.mailboxStorageStatus().error;
    this.isEmailAddress = !!this.data.mailbox;
    this.isForwardAddress = !this.data.mailbox;
    this.isCatchAll = data.isCatchAll;
  }

  public static fromApiData(data: EmailAddressApiData): EmailAddress {
    return new EmailAddress(data);
  }

  public static useLoadById(id: string): EmailAddress {
    const data = mittwaldApi.mailGetMailAddress
      .getResource({ path: { mailAddressId: id } })
      .useWatchData();

    return new EmailAddress(data);
  }

  public static useLoadByPathParam(): EmailAddress {
    const { emailAddressId } = usePathParams("emailAddressId");
    return EmailAddress.useLoadById(emailAddressId);
  }

  public static useTryLoadById(id: string): EmailAddress | undefined {
    const data = mittwaldApi.mailGetMailAddress
      .getResource({ path: { mailAddressId: id } })
      .useWatchData({ optional: true });

    return data ? EmailAddress.fromApiData(data) : undefined;
  }

  public useEmailAddressList(): EmailAddressList {
    return EmailAddressList.useLoadAllByProjectId(this.data.projectId);
  }

  public useIngressList(): IngressList {
    return IngressList.useLoadAllByProjectId(this.data.projectId);
  }

  public getCurrentStorage(): number {
    return this.data.mailbox?.storageInBytes.current.value ?? 0;
  }

  public getStorageLimit(): number {
    return this.data.mailbox?.storageInBytes.limit ?? 0;
  }

  public static async createNewEmailAddress(
    values: NewEmailAddressInputs,
    projectId: string,
  ): Promise<string> {
    const response = await mittwaldApi.mailCreateMailAddress.request({
      path: {
        projectId,
      },
      requestBody: {
        address: values.address,
        isCatchAll: values.isCatchAll,
        mailbox: {
          enableSpamProtection: values.mailbox.enableSpamProtection,
          password: values.mailbox.password,
          quotaInBytes: this.gbToByte(values.mailbox.storage),
        },
      },
    });

    if (
      response.status === 400 &&
      response.content.message?.includes("is_catch_all")
    ) {
      return "isCatchAll";
    }

    assertStatus(response, 201);

    return response.content.id;
  }

  public static async createNewForwardAddress(
    values: NewForwardAddressInputs,
    projectId: string,
  ): Promise<string | false> {
    if (values.forwardAddresses.length <= 0) {
      return false;
    }

    const response = await api.mailCreateMailAddress({
      path: {
        projectId,
      },
      requestBody: values,
    });

    assertStatus(response, 201);

    return response.content.id;
  }

  public async updateCatchAll(active: boolean): Promise<void> {
    const catchAllResponse =
      await mittwaldApi.mailUpdateMailAddressCatchAll.request({
        path: {
          mailAddressId: this.id,
        },
        requestBody: {
          active,
        },
      });

    assertStatus(catchAllResponse, 204);
  }

  public async updateAutoresponder(
    values: UpdateAutoresponderInputs,
  ): Promise<void> {
    const response = await api.mailUpdateMailAddressAutoresponder({
      path: {
        mailAddressId: this.id,
      },
      requestBody: {
        autoResponder: {
          active: values.active,
          message:
            (values.message
              ? values.message
              : this.data.autoResponder.message) ?? "",
          startsAt: values.period?.[0] ?? this.data.autoResponder.startsAt,
          expiresAt: values.period?.[1] ?? this.data.autoResponder.startsAt,
        },
      },
    });

    assertStatus(response, 204);
  }

  public async updateForwards(values: UpdateForwardsInputs): Promise<void> {
    const response = await api.mailUpdateMailAddressForwardAddresses({
      path: {
        mailAddressId: this.id,
      },
      requestBody: {
        forwardAddresses: values.forwardAddresses,
      },
    });

    assertStatus(response, 204);
  }

  public async updateEmailAddress(
    values: UpdateMailAddressInputs,
  ): Promise<void> {
    const response = await api.mailUpdateMailAddressAddress({
      path: {
        mailAddressId: this.id,
      },
      requestBody: {
        address: values.address,
      },
    });

    assertStatus(response, 204);
  }

  public async updatePassword(values: UpdatePasswordInputs): Promise<void> {
    const response = await api.mailUpdateMailAddressPassword({
      path: {
        mailAddressId: this.id,
      },
      requestBody: {
        password: values.password,
      },
    });

    assertStatus(response, 204);
  }

  public async updateSpamProtection(
    values: UpdateSpamProtectionInputs,
  ): Promise<void> {
    const response = await api.mailUpdateMailAddressSpamProtection({
      path: {
        mailAddressId: this.id,
      },
      requestBody: {
        spamProtection: {
          ...values,
          relocationMinSpamScore: EmailAddress.frontendScoreToBackendScore(
            values.relocationMinSpamScore,
          ),
        },
      },
    });

    assertStatus(response, 204);
  }

  public async updateQuota(values: UpdateQuotaInputs): Promise<void> {
    const response = await api.mailUpdateMailAddressQuota({
      path: {
        mailAddressId: this.id,
      },
      requestBody: {
        quotaInBytes: EmailAddress.gbToByte(values.storage),
      },
    });

    assertStatus(response, 204);
  }

  public async delete(): Promise<void> {
    const response = await api.mailDeleteMailAddress({
      path: {
        mailAddressId: this.id,
      },
    });

    assertStatus(response, 204);
  }

  public mailboxStoragePageStatus: CheckPageState = () => {
    const status = this.mailboxStorageStatus();
    return status.error ? "error" : status.warning ? "warning" : null;
  };

  public mailboxStorageStatus = (): StatusTypesProps => {
    const mailboxFilledPercent = this.data.mailbox
      ? this.getCurrentStorage() / this.getStorageLimit()
      : 0;

    return {
      info: false,
      success: true,
      warning: mailboxFilledPercent >= EmailAddress.quotaUsageWarning,
      error: mailboxFilledPercent >= EmailAddress.quotaUsageError,
    };
  };

  public static getEmailAddressComponents = (
    address: string = "",
  ): { local: string; domain: string } => {
    const parts = arrayAccessor(address.split("@"));
    return { local: parts.at(0), domain: parts.optionalAt(1) ?? "" };
  };

  public static getEmailAddressLocalPart = (address: string): string => {
    return this.getEmailAddressComponents(address).local;
  };

  public getLocalPart = (): string => {
    return EmailAddress.getEmailAddressComponents(this.address).local;
  };

  public static getEmailAddressDomainPart = (address: string): string => {
    return this.getEmailAddressComponents(address).domain;
  };

  public getDomainPart = (): string => {
    return EmailAddress.getEmailAddressComponents(this.address).domain;
  };

  public static byteToGB(byte: number): number {
    return Math.round((byte / 1024 / 1024 / 1024) * 10) / 10;
  }

  public static gbToByte(gb: number): number {
    return gb * 1024 * 1024 * 1024;
  }

  public isPasswordOld = (): boolean => {
    if (!this.data.mailbox) {
      return false;
    }
    const diff =
      new Date().getTime() -
      new Date(this.data.mailbox.passwordUpdatedAt).getTime();
    return diff / 1000 > EmailAddress.passwordAgeWarningInSeconds;
  };

  public static backendScoreToFrontendScore(backendScore: number): string {
    return backendScore >= 8 ? "8" : backendScore >= 5 ? "7" : "4";
  }

  public static frontendScoreToBackendScore(frontendScore: string): number {
    return frontendScore == "4" ? 4 : frontendScore == "7" ? 7 : 8;
  }
}

export default EmailAddress;
