import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import User from "../user/User";
import { ProjectMembershipList, ProjectRole, ProjectRoleName } from "./";

export type ProjectMembershipApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Membership_ProjectMembership;

export interface UpdateMembershipInputs {
  role: ProjectRoleName;
  expiresAt?: string;
}

export class ProjectMembership {
  public readonly data: ProjectMembershipApiData;
  public readonly id: string;
  public readonly role: ProjectRole;

  public readonly userId: string;

  private constructor(data: ProjectMembershipApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.role = new ProjectRole(data.role);

    this.userId = data.userId;
  }

  public static useLoadOwn(projectId: string): ProjectMembership {
    const data = mittwaldApi.projectGetSelfMembershipForProject
      .getResource({ path: { projectId } })
      .useWatchData();

    return ProjectMembership.fromApiData(data);
  }

  public static useLoad(
    projectId: string,
    membershipId: string,
  ): ProjectMembership {
    const data = mittwaldApi.projectGetProjectMembership
      .getResource({
        path: {
          projectMembershipId: membershipId,
        },
      })
      .useWatchData();

    return ProjectMembership.fromApiData(data);
  }

  public static fromApiData(data: ProjectMembershipApiData): ProjectMembership {
    return new ProjectMembership(data);
  }

  public static async loadOwn(projectId: string): Promise<ProjectMembership> {
    const data = await mittwaldApi.projectGetSelfMembershipForProject.request({
      path: { projectId },
    });

    assertStatus(data, 200);
    return ProjectMembership.fromApiData(data.content);
  }

  public useUser(): User {
    return User.useLoadById(this.data.userId);
  }

  public useIsMe(): boolean {
    return this.useUser().useIsMe();
  }

  public useIsLastProjectOwner = (): boolean => {
    const members = ProjectMembershipList.useTryLoadByProjectId(
      this.role.is("owner") ? this.data.projectId : undefined,
    );

    if (!this.data.inherited || !members) {
      return false;
    }

    return members.items.filter((m) => m.role.is("owner")).length <= 1;
  };

  public async removeMember(): Promise<void> {
    const response = await mittwaldApi.projectDeleteProjectMembership.request({
      path: {
        projectMembershipId: this.data.id,
      },
    });

    assertStatus(response, 204);
  }

  public async leaveProject(): Promise<void> {
    const response = await mittwaldApi.projectDeleteProjectMembership.request({
      path: { projectMembershipId: this.data.id },
    });

    assertStatus(response, 204);
  }

  public async update(values: UpdateMembershipInputs): Promise<void> {
    const response = await mittwaldApi.projectUpdateProjectMembership.request({
      path: { projectMembershipId: this.id },
      requestBody: {
        role: values.role,
        expiresAt: values.expiresAt,
      },
    });

    assertStatus(response, 204);
  }
}

export default ProjectMembership;
