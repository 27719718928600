import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { MySqlUser } from "./MySqlUser";

export class MySqlUserList extends ListModel<MySqlUser> {
  public static useLoadAllByDatabaseId(databaseId: string): MySqlUserList {
    const mySqlUsers = mittwaldApi.databaseListMysqlUsers
      .getResource({
        path: {
          mysqlDatabaseId: databaseId,
        },
      })
      .useWatchData()
      .map((d) => MySqlUser.fromApiData(d));

    return new MySqlUserList(mySqlUsers);
  }

  public static useLoadAllByPathParam(): MySqlUserList {
    const { databaseId } = usePathParams("databaseId");
    return MySqlUserList.useLoadAllByDatabaseId(databaseId);
  }

  public static useTryLoadAllByDatabaseId(
    databaseId?: string,
  ): MySqlUserList | undefined {
    const databaseUsers = mittwaldApi.databaseListMysqlUsers
      .getResource(
        databaseId
          ? {
              path: {
                mysqlDatabaseId: databaseId,
              },
            }
          : null,
      )
      .useWatchData()
      ?.map((d) => MySqlUser.fromApiData(d));

    return databaseUsers ? new MySqlUserList(databaseUsers) : undefined;
  }

  public static async loadAllByDatabaseId(
    databaseId: string,
  ): Promise<MySqlUserList> {
    const databaseUsers = await mittwaldApi.databaseListMysqlUsers.request({
      path: {
        mysqlDatabaseId: databaseId,
      },
    });

    assertStatus(databaseUsers, 200);

    return new MySqlUserList(
      databaseUsers.content.map((d) => MySqlUser.fromApiData(d)),
    );
  }

  public static async requestMainUserId(
    databaseId: string,
  ): Promise<string | undefined> {
    const response = await mittwaldApi.databaseGetMysqlDatabase.request({
      path: {
        mysqlDatabaseId: databaseId,
      },
    });

    assertStatus(response, 200);

    return response.content.mainUser?.id;
  }
}
