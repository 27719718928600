import { FormController } from "@mittwald/flow-components/dist/components/Form";
import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import React, { FC } from "react";
import { CustomerUpdateInputs } from "../../../../../../model/customer/Customer";
import VirtualPageViewTracking from "../../../../../../widgets/components/tracking/components/VirtualPageViewTracking";
import ContactFormInputs from "../../../../customer/contractPartner/components/ContactFormInputs";

interface Props {
  trackingId?: string;
  form: FormController<CustomerUpdateInputs>;
  noReturn?: boolean;
}

export const ContractPartnerStep: FC<Props> = (props) => {
  return (
    <TranslationProvider name="contractPartnerStepContent" type="component">
      <WizardStep
        form={props.form}
        id="contractPartner"
        indicatorText="contractPartnerStep"
        noReturn={props.noReturn}
      >
        <Suspense loadingView={<LoadingView _height={250} />}>
          {props.trackingId && (
            <VirtualPageViewTracking
              identifier={props.trackingId}
              name="checkout/contractPartner"
            />
          )}

          <Section.Item headline="contractPartner">
            <Text i18n="contractPartnerDescription" />
            <ContactFormInputs type="owner" />
          </Section.Item>
        </Suspense>
      </WizardStep>
    </TranslationProvider>
  );
};

export default ContractPartnerStep;
