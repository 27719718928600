import { DateTime } from "luxon";
import { MittwaldApi } from "../../api/Mittwald";
import Conversation from "./Conversation";

type ItemData =
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_Message
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_StatusUpdate
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_ServiceRequest;

export abstract class ConversationItem {
  public readonly conversation: Conversation;
  public readonly index: number;
  public readonly createdAt: DateTime;

  protected constructor(
    conversation: Conversation,
    index: number,
    itemData: ItemData,
  ) {
    this.conversation = conversation;
    this.index = index;
    this.createdAt = DateTime.fromISO(itemData.createdAt);
  }

  public usePrev(): ConversationItem | undefined {
    return this.conversation.useItemAtIndex(this.index - 1);
  }

  public useNext(): ConversationItem | undefined {
    return this.conversation.useItemAtIndex(this.index + 1);
  }

  public isFromSameDay(item: ConversationItem): boolean {
    return item.createdAt.startOf("day").equals(this.createdAt.startOf("day"));
  }

  public isFromToday(): boolean {
    return DateTime.now().startOf("day").equals(this.createdAt.startOf("day"));
  }

  public isFromSameTime(item: ConversationItem): boolean {
    return item.createdAt
      .startOf("minute")
      .equals(this.createdAt.startOf("minute"));
  }
}

export default ConversationItem;
