import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { useMemo } from "react";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { DomainList } from "./DomainList";
import Ingress from "./Ingress";

export class IngressList extends ListModel<Ingress> {
  public static useLoadAllWithDomainByProjectId(
    projectId?: string,
  ): IngressList {
    const domains = DomainList.useLoadAllByProjectId(projectId);

    const ingresses = mittwaldApi.ingressListIngresses
      .getResource(projectId ? { query: { projectId } } : {})
      .useWatchData();

    const ingressMemo = useMemo(
      () =>
        ingresses.map((i) => {
          const domain = domains.items.find(
            (d) => d.data.domain === i.hostname && !d.data.deleted,
          );

          return Ingress.fromApiData(i, domain);
        }),
      [ingresses],
    );

    return new IngressList(ingressMemo);
  }

  public static useLoadAllByProjectId(projectId: string): IngressList {
    const ingresses = mittwaldApi.ingressListIngresses
      .getResource({ query: { projectId } })
      .useWatchData();

    const ingressMemo = useMemo(
      () => ingresses.map((i) => Ingress.fromApiData(i)),
      [ingresses],
    );

    return new IngressList(ingressMemo);
  }

  public static useLoadAllAccessible(): IngressList {
    const ingresses = mittwaldApi.ingressListIngresses
      .getResource({})
      .useWatchData();

    const ingressMemo = useMemo(
      () => ingresses.map((i) => Ingress.fromApiData(i)),
      [ingresses],
    );

    return new IngressList(ingressMemo);
  }

  public static useLoadAllAccessibleWithDomains(): IngressList {
    const domains = DomainList.useTryLoadByQuery();

    const ingresses = mittwaldApi.ingressListIngresses
      .getResource({})
      .useWatchData();

    const ingressMemo = useMemo(
      () =>
        ingresses.map((i) => {
          const domain = domains.items.find(
            (d) => d.data.domain === i.hostname && !d.data.deleted,
          );

          return Ingress.fromApiData(i, domain);
        }),
      [ingresses],
    );
    return new IngressList(ingressMemo);
  }

  public getDefaultIngress(): Ingress | undefined {
    return this.items.find((i) => i.isEnabled && i.isDefault);
  }

  public useEnabledIngressList(): IngressList {
    return useMemo(
      () => new IngressList(this.items.filter((i) => i.isEnabled)),
      this.items,
    );
  }

  public useIsDefaultIngress(ingress: Ingress): boolean {
    const defaultIngress = this.getDefaultIngress();

    if (ingress.isDefault) {
      return true;
    }

    return !!(
      defaultIngress &&
      ingress.isSubdomain &&
      ingress.hostname
        .toLowerCase()
        .endsWith(defaultIngress.hostname.toLowerCase())
    );
  }

  public static useIngressesCompatibleWithCertificate(
    projectId: string,
    certificate: string,
  ): IngressList {
    const data = mittwaldApi.ingressListIngressesCompatibleWithCertificate
      .getResource({
        requestBody: { projectId, certificate },
      })
      .useWatchData();

    return new IngressList(data.map((i) => Ingress.fromApiData(i)));
  }

  public static async getIngressesCompatibleWithCertificate(
    projectId: string,
    certificate: string,
  ): Promise<IngressList> {
    const response =
      await mittwaldApi.ingressListIngressesCompatibleWithCertificate.request({
        requestBody: { projectId, certificate },
      });

    assertStatus(response, 200);
    return new IngressList(response.content.map((i) => Ingress.fromApiData(i)));
  }

  public filterExternalCertificates(): IngressList {
    const items = this.items.filter((i) => i.ssl.type === "externalSSL");
    return new IngressList(items);
  }
}
