import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { ProjectBase, ProjectMembership, ProjectMembershipApiData } from "./";

export class ProjectMembershipList extends ListModel<ProjectMembership> {
  public static fromApiData(
    data: ProjectMembershipApiData[],
  ): ProjectMembershipList {
    return new ProjectMembershipList(
      data.map((d) => ProjectMembership.fromApiData(d)),
    );
  }

  public static useLoadByProjectId(projectId: string): ProjectMembershipList {
    const data = mittwaldApi.projectListMembershipsForProject
      .getResource({
        path: {
          projectId,
        },
      })
      .useWatchData();

    return ProjectMembershipList.fromApiData(data);
  }

  public static async getFromProject(
    project: ProjectBase,
  ): Promise<ProjectMembershipList> {
    const response = await mittwaldApi.projectListMembershipsForProject.request(
      {
        path: {
          projectId: project.id,
        },
      },
    );

    assertStatus(response, 200);

    return ProjectMembershipList.fromApiData(response.content);
  }

  public static useLoadAll(): ProjectMembershipList {
    const data = mittwaldApi.projectListProjectMemberships
      .getResource({})
      .useWatchData();

    return ProjectMembershipList.fromApiData(data);
  }

  public static useTryLoadByProjectId(
    projectId?: string,
  ): ProjectMembershipList | undefined {
    const data = mittwaldApi.projectListMembershipsForProject
      .getResource(
        projectId
          ? {
              path: {
                projectId,
              },
            }
          : null,
      )
      .useWatchData({ optional: true });

    return data ? ProjectMembershipList.fromApiData(data) : undefined;
  }

  public static async loadByProjectId(
    projectId: string,
  ): Promise<ProjectMembershipList> {
    const response = await mittwaldApi.projectListMembershipsForProject.request(
      {
        path: {
          projectId,
        },
      },
    );

    assertStatus(response, 200);

    return ProjectMembershipList.fromApiData(response.content);
  }
}

export default ProjectMembershipList;
