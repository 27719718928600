import React, { FC } from "react";
import DisableForm from "../../../../../../../../components/DisableForm";
import { ProSpaceArticle } from "../../../../../../../../model/article";
import Bytes from "../../../../../../../../model/misc/Bytes";
import Order from "../../../../../../../../model/order/Order";
import { PromoCode } from "../../../../../../../../widgets/utils/promoCode";
import PerMonthWithoutFreeTrialText from "../../../../../../server/modals/OrderServerWizard/components/PerMonthWithoutFreeTrialText";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { InfoBox } from "@mittwald/flow-components/dist/components/InfoBox";
import { Skeleton } from "@mittwald/flow-components/dist/components/Skeleton";
import { DateTime } from "luxon";
import { H2 } from "@mittwald/flow-components/dist/components/H2";

interface Props {
  selectedArticle: ProSpaceArticle;
  watchedStorageSize: Bytes;
  freeTrial: boolean;
  promotion?: PromoCode;
}

export const PriceSection: FC<Props> = (props) => {
  const { promotion, watchedStorageSize, freeTrial, selectedArticle } = props;

  const useArticleMoneyValue = (): number | bigint =>
    selectedArticle
      .useOrderPreview({ storageSize: watchedStorageSize })
      .total.toMoneyValueInput();

  return (
    <Box _alignItems="center" _flexDirection="column" _itemGap="m" _mt="m">
      <InfoBox.Price
        price={{
          useValue: () => {
            const articleMoneyValue = useArticleMoneyValue();
            return freeTrial || promotion ? 0 : articleMoneyValue;
          },
          loadingView: (
            <DisableForm>
              <Skeleton _width={70} />
            </DisableForm>
          ),
        }}
        priceSubline={
          freeTrial || promotion ? (
            <PerMonthWithoutFreeTrialText
              freeTrialUntil={
                promotion
                  ? DateTime.now().plus({ day: promotion.promotionDays })
                  : Order.freeTrialUntil
              }
              price={{
                useValue: useArticleMoneyValue,
                loadingView: (
                  <DisableForm>
                    <Skeleton _width={140} />
                  </DisableForm>
                ),
              }}
            />
          ) : (
            "perMonth"
          )
        }
      />
      {!promotion && <CheckBox name="useFreeTrial" title="freeTrial" />}
      {promotion && (
        <H2
          i18n={{
            id: "promotion",
            values: { days: promotion.promotionDays },
          }}
        />
      )}
    </Box>
  );
};

export default PriceSection;
