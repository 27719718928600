import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import OrderResourceSelect from "@mittwald/flow-components/dist/components/OrderResourceSelect/OrderResourceSelect";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";
import { ProSpaceArticle } from "../../../../../../model/article";
import ProSpaceArticleUI from "../../../../../../model/ui/article/ProSpaceArticleUI";
import { Section as NewSection } from "@mittwald/flow-react-components";

interface Props {
  selectedProSpaceArticles: ProSpaceArticle[];
  liteSelected: boolean;
  currentArticle?: ProSpaceArticle;
  isInWidget?: boolean;
}

export const ConfigurationResourceSection: FC<Props> = (props) => {
  const { selectedProSpaceArticles, liteSelected, currentArticle, isInWidget } =
    props;

  const machineTypeOptions = ProSpaceArticleUI.buildArticleSelectBoxOptions(
    selectedProSpaceArticles,
    currentArticle,
  );

  const form = useFormContext();
  const resourceFilter = form.watch("resourceFilter");

  return (
    <NewSection>
      <Section.Item headline={isInWidget ? "widget.resource" : "resource"}>
        {!liteSelected && (
          <>
            <SelectBox
              name="resourceFilter"
              options={ProSpaceArticleUI.buildResourceSelectBoxOptions(
                currentArticle,
              )}
            />
            <Text
              i18n={`resourceDescription.${resourceFilter}`}
              small
              squished
            />
          </>
        )}
        <OrderResourceSelect
          breakpoint={300}
          minOptionWidth={120}
          name="articleId"
          options={machineTypeOptions}
        />
      </Section.Item>
    </NewSection>
  );
};

export default ConfigurationResourceSection;
