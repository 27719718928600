import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { Money } from "../misc/Money";
import Contributor from "./Contributor";
import * as R from "remeda";

export type ExtensionApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Marketplace_Extension;

export type MarketplaceContext =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Marketplace_Context;

export type MarketplaceExternalComponent =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Marketplace_ExternalComponent;

export interface FrontendFragment {
  additionalProperties?: {
    icon?: string;
    title?: string | Record<string, string>;
  };
  url: string;
}

export type FrontendFragments = Record<string, FrontendFragment>;

const staticPrices = [
  // Postmaster
  {
    id: "018fba26-2791-70f7-953c-6becc21f6df5",
    price: 2500,
  },
  // Passwortschutz
  {
    id: "ede29562-9a5b-4bee-81c3-479e878bf259",
    price: 149,
  },
  // Floppy
  {
    id: "f31910f5-f582-4163-bdd9-a912a09ede70",
    price: 500,
  },
  // Smart Invoice
  {
    id: "be9386b0-5a8e-4829-a2d0-adcdacfa1991",
    price: 1000,
  },
  // PHP-Einstellungen
  {
    id: "ddaeea02-a622-4a51-ba2e-e1a102c3dfab",
    price: 149,
  },
  // Bagel
  {
    id: "e90dcbd5-4c02-415b-9942-395d255bf00d",
    price: 1000,
  },
  // Treesize
  {
    id: "e2fff19a-e58c-4231-b6a4-ef6886ed6f39",
    price: 149,
  },
  // To Do Liste
  {
    id: "e6c56a47-c4dd-474f-b378-48fe2c930b18",
    price: 500,
  },
  // GitLab Deploy
  {
    id: "55438185-001c-41ab-95b5-ca48828f93cd",
    price: 3900,
  },
];

class Extension {
  public readonly data: ExtensionApiData;
  public readonly id: string;
  public readonly name: string;
  public readonly isDisabled: boolean;
  public readonly isBlocked: boolean;
  public readonly price?: ReturnType<typeof Money>;
  public readonly context: "project" | "customer";
  public readonly scopes: string[];
  public readonly subTitle: string;
  public readonly detailedDescriptionMarkdown?: string;
  public readonly frontendFragments: FrontendFragments;

  public constructor(data: ExtensionApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.name = data.name;
    this.isDisabled = data.state === "disabled";
    this.isBlocked = data.state === "blocked";

    const staticPrice = staticPrices.find((p) => p.id === this.id);
    this.price = staticPrice
      ? Money({ amount: staticPrice.price, currency: "EUR" })
      : undefined;
    this.context = data.context;
    this.scopes = data.scopes;
    this.subTitle = data.subTitle.de;
    this.detailedDescriptionMarkdown = data.detailedDescriptions?.de.markdown;
    this.frontendFragments = this.normalizeFrontendFragments(
      data.frontendFragments,
    );
  }

  public static useLoadById(extensionId: string): Extension {
    const data = mittwaldApi.extensionGetExtension
      .getResource({ path: { extensionId } })
      .useWatchData();

    return new Extension(data);
  }

  public static useTryLoadById(
    extensionId: string | undefined,
  ): Extension | undefined {
    const data = mittwaldApi.extensionGetExtension
      .getResource(extensionId ? { path: { extensionId } } : null)
      .useWatchData({ optional: true });

    return data ? new Extension(data) : undefined;
  }

  public static async loadById(extensionId: string): Promise<Extension> {
    const data = await mittwaldApi.extensionGetExtension
      .getResource({ path: { extensionId } })
      .load();

    return new Extension(data);
  }

  public useContributor(): Contributor {
    return Contributor.useLoadById(this.data.contributorId);
  }

  public async getContributor(): Promise<Contributor> {
    return Contributor.loadById(this.data.contributorId);
  }

  public getFrontendFragment(fragment: string): FrontendFragment | undefined {
    return this.frontendFragments[fragment];
  }

  public hasFrontendFragment(fragment: string): boolean {
    return !!this.getFrontendFragment(fragment);
  }

  private normalizeFrontendFragments(
    fragments: FrontendFragments = {},
  ): FrontendFragments {
    return R.mapValues(fragments, (value) =>
      R.evolve(value, {
        additionalProperties: (val) =>
          R.mapValues(val, (v) => {
            if (typeof v === "string" && v.startsWith("{") && v.endsWith("}")) {
              return JSON.parse(v);
            }
            return v;
          }),
      }),
    );
  }
}

export default Extension;
