import { DateTime } from "luxon";
import { MittwaldApi } from "../../api/Mittwald";
import Conversation from "./Conversation";
import ConversationItem from "./ConversationItem";

type ApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_ServiceRequest;

export class ConversationServiceRequest extends ConversationItem {
  public readonly type: ApiSchema["messageContent"];
  public readonly payload: ApiSchema["meta"];
  public readonly data: ApiSchema;

  private constructor(
    conversation: Conversation,
    index: number,
    data: ApiSchema,
  ) {
    super(conversation, index, data);
    this.type = data.messageContent;
    this.payload = data.meta;
    this.data = Object.freeze(data);
  }

  public static fromApiData(
    conversation: Conversation,
    index: number,
    data: ApiSchema,
  ): ConversationServiceRequest {
    return new ConversationServiceRequest(conversation, index, data);
  }

  public isFromToday(): boolean {
    return DateTime.now().startOf("day").equals(this.createdAt.startOf("day"));
  }
}
