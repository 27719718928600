import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import api from "../../api/clients/mittwald";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";

export type DeliveryBoxApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Mail_Deliverybox;

export interface NewDeliveryBoxInputs {
  description: string;
  password: string;
}

export interface UpdateDeliveryBoxDescriptionInputs {
  description: string;
}

export interface UpdatePasswordInputs {
  password: string;
}

export class DeliveryBox {
  public readonly id: string;
  public readonly data: DeliveryBoxApiData;
  public readonly description: string;
  public readonly name: string;
  public static readonly passwordAgeWarningInSeconds = 60 * 60 * 24 * 30 * 3; // three months / 90 days

  protected constructor(data: DeliveryBoxApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.description = data.description;
    this.name = data.name;
  }

  public static fromApiData(data: DeliveryBoxApiData): DeliveryBox {
    return new DeliveryBox(data);
  }

  public static useLoadById(id: string): DeliveryBox {
    const data = mittwaldApi.mailGetDeliveryBox
      .getResource({
        path: {
          deliveryBoxId: id,
        },
      })
      .useWatchData();

    return new DeliveryBox(data);
  }

  public static useLoadByPathParam(): DeliveryBox {
    const { deliveryboxId } = usePathParams("deliveryboxId");

    return DeliveryBox.useLoadById(deliveryboxId);
  }

  public static async createNew(
    values: NewDeliveryBoxInputs,
    projectId: string,
  ): Promise<string> {
    const response = await api.mailCreateDeliverybox({
      path: {
        projectId,
      },
      requestBody: {
        description: values.description,
        password: values.password,
      },
    });

    assertStatus(response, 201);

    return response.content.id;
  }

  public async updateDescription(
    values: UpdateDeliveryBoxDescriptionInputs,
  ): Promise<void> {
    const response = await api.mailUpdateDeliveryBoxDescription({
      path: {
        deliveryBoxId: this.id,
      },
      requestBody: {
        description: values.description,
      },
    });

    assertStatus(response, 204);
  }

  public async updatePassword(values: UpdatePasswordInputs): Promise<void> {
    const response = await api.mailUpdateDeliveryBoxPassword({
      path: {
        deliveryBoxId: this.id,
      },
      requestBody: {
        password: values.password,
      },
    });

    assertStatus(response, 204);
  }

  public async delete(): Promise<void> {
    const response = await api.mailDeleteDeliveryBox({
      path: {
        deliveryBoxId: this.id,
      },
    });

    assertStatus(response, 204);
  }

  public isPasswordOld = (): boolean => {
    const diff =
      new Date().getTime() - new Date(this.data.passwordUpdatedAt).getTime();
    return diff / 1000 > DeliveryBox.passwordAgeWarningInSeconds;
  };
}

export default DeliveryBox;
