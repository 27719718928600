import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import { DateTime } from "@mittwald/flow-components/dist/components/DateTimeText";
import React, { FC } from "react";

interface Props {
  customerName: string;
  suspendedSince: string;
}

export const CustomerSuspendedBanner: FC<Props> = (props: Props) => {
  const { customerName } = props;
  const suspendedSince = (
    <DateTime format="date" value={props.suspendedSince} />
  );

  return (
    <>
      <Banner
        description={{
          id: "customerSuspendedBannerDescription",
          values: {
            suspendedSince,
            customerName,
          },
        }}
        headline="customerSuspendedBanner"
        warning
      />
    </>
  );
};

export default CustomerSuspendedBanner;
