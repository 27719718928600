import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { DateTime } from "luxon";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import modelRelationFactory from "../misc/modelRelation/modelRelationFactory";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import Notification from "../notification/Notification";
import ConversationCategory from "./ConversationCategory";
import ConversationItem from "./ConversationItem";
import ConversationMessage, { ConversationFile } from "./ConversationMessage";
import { ConversationServiceRequest } from "./ConversationServiceRequest";
import ConversationStatusUpdate from "./ConversationStatusUpdate";

export type ConversationApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_Conversation;
export type AggregateReferenceApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_AggregateReference;
export type ShareableAggregateReferenceApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_ShareableAggregateReference;
export type ConversationUserApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_User;
export type ConversationMembersApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_ConversationMembers;
export interface ConversationAddNewMessageInput {
  content: string;
  fileIds?: string[];
}

export interface ConversationCreateInput {
  categoryId: string;
  title: string | undefined;
  relatedTo: {
    id: string;
    aggregate: string;
    domain: string;
  };
  sharedWith?: AggregateReferenceApiSchema;
}

export class Conversation {
  public readonly id: string;
  public readonly data: ConversationApiSchema;
  public readonly isClosed: boolean;
  public readonly isOpen: boolean;
  public readonly category: ConversationCategory | undefined;
  public readonly createdAt: DateTime;
  public readonly relatedTo?: ModelRelation;

  private constructor(data: ConversationApiSchema) {
    this.id = data.conversationId;
    this.createdAt = DateTime.fromISO(data.createdAt);
    this.data = Object.freeze(data);
    this.isClosed = data.status === "closed";
    this.isOpen = data.status !== "closed";
    this.category = data.category
      ? ConversationCategory.fromApiData(data.category)
      : undefined;

    this.relatedTo = data.relatedTo
      ? modelRelationFactory(data.relatedTo)
      : undefined;
  }

  public static fromApiData(data: ConversationApiSchema): Conversation {
    return new Conversation(data);
  }

  public static useLoadById(id: string): Conversation {
    const data = mittwaldApi.conversationGetConversation
      .getResource({
        path: { conversationId: id },
      })
      .useWatchData();

    return Conversation.fromApiData(data);
  }
  public static useLoadByPathParam(): Conversation {
    const { conversationId } = usePathParams("conversationId");
    return Conversation.useLoadById(conversationId);
  }

  public static useTryLoadById(id: string): Conversation | undefined {
    const data = mittwaldApi.conversationGetConversation
      .getResource({
        path: { conversationId: id },
      })
      .useWatchData({ optional: true });

    return data ? Conversation.fromApiData(data) : undefined;
  }

  public static async loadById(id: string): Promise<Conversation> {
    const response = await mittwaldApi.conversationGetConversation.request({
      path: { conversationId: id },
    });

    assertStatus(response, 200);

    return Conversation.fromApiData(response.content);
  }

  public async close(): Promise<void> {
    const response =
      await mittwaldApi.conversationSetConversationStatus.request({
        requestBody: { status: "closed" },
        path: { conversationId: this.id },
      });

    assertStatus(response, 200);
  }

  public async requestFileUploadToken(): Promise<string> {
    const response = await mittwaldApi.conversationRequestFileUpload.request({
      path: { conversationId: this.id },
    });

    assertStatus(response, 201);
    return response.content.uploadToken;
  }

  public hasConversationUnreadMessages(
    unreadNotifications: Notification[],
  ): boolean {
    const unreadConversationIds = unreadNotifications
      .filter(
        (notification) =>
          notification.data.type === "conversation.conversation.messageSent",
      )
      .map((notification) => notification.data.reference.id);

    return unreadConversationIds.includes(this.id);
  }

  public static async createNew(
    values: ConversationCreateInput,
  ): Promise<Conversation> {
    const { relatedTo, title, categoryId, sharedWith } = values;

    const response = await mittwaldApi.conversationCreateConversation.request({
      requestBody: {
        title: title || "",
        categoryId,
        relatedTo: ModelRelationType.unknown.matches(relatedTo)
          ? undefined
          : relatedTo,
        sharedWith: ModelRelationType.unknown.matches(relatedTo)
          ? undefined
          : (sharedWith as ShareableAggregateReferenceApiSchema),
      },
    });

    assertStatus(response, 201);

    return Conversation.loadById(response.content.conversationId);
  }

  public async updateTitle(title: string): Promise<Conversation> {
    const conversation = await Conversation.loadById(this.id);

    if (!conversation.category) {
      throw new Error("Conversation has no category");
    }

    const response = await mittwaldApi.conversationUpdateConversation.request({
      path: {
        conversationId: conversation.id,
      },
      requestBody: {
        title: title,
      },
    });

    assertStatus(response, 200);

    return Conversation.loadById(response.content.conversationId);
  }

  public async addMessage(data: ConversationAddNewMessageInput): Promise<void> {
    const response = await mittwaldApi.conversationCreateMessage.request({
      requestBody: { messageContent: data.content, fileIds: data.fileIds },
      path: { conversationId: this.id },
    });

    assertStatus(response, 201);
  }

  public useItems(): ConversationItem[] {
    const data = mittwaldApi.conversationListMessagesByConversation
      .getResource({
        path: {
          conversationId: this.id,
        },
      })
      .useWatchData();

    return data.map((itemData, index) => {
      if (itemData.type === "MESSAGE") {
        return ConversationMessage.fromApiData(this, index, itemData);
      } else if (itemData.type === "STATUS_UPDATE") {
        return ConversationStatusUpdate.fromApiData(this, index, itemData);
      } else {
        return ConversationServiceRequest.fromApiData(this, index, itemData);
      }
    });
  }

  public useItemAtIndex(index: number): ConversationItem | undefined {
    return this.useItems()[index];
  }

  public useDetailsLink(): string {
    const buildLink = useLinkBuilder();
    return buildLink("conversationDetails", { conversationId: this.id });
  }

  public isNew(userId?: string): boolean {
    return this.data.lastMessageBy?.userId !== userId;
  }

  public valueOf(): number {
    return this.createdAt.toMillis();
  }

  public static async loadConversationFileContent(
    file: ConversationFile,
    conversationId: string,
  ): Promise<string | undefined> {
    const tokenResponse =
      await mittwaldApi.conversationGetFileAccessToken.request({
        path: {
          fileId: file.id,
          conversationId,
        },
      });

    assertStatus(tokenResponse, 200);

    const fileResponse = await mittwaldApi.fileGetFile.request({
      path: { fileId: file.id },
      header: {
        Accept: "text/plain;base64",
        Token: tokenResponse.content.accessToken,
        "x-access-token": undefined,
      },
    });
    assertStatus(fileResponse, 200);

    return fileResponse.content;
  }
}

export default Conversation;
